import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide from './Slide';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg';

import './ProjectsSlider.scss';
import ButtonIcon from '../../ButtonIcon/ButtonIcon';

const ProjectsSlider = () => {

    const slider = useRef(null);

    const sliderSettings = {
        slidesToShow: 1,
        arrows: false,
        swipe: true,
        swipeToSlide: true,
        infinite: true,
        speed: 1000,
        slidesToScroll: 1
    }

    const prevSlide = () => slider.current.slickPrev();
    const nextSlide = () => slider.current.slickNext();

    const data = useStaticQuery(graphql`
    query {
        githubViewer {
            login
            pinnedItems {
              nodes {
                description
                homepageUrl
                id
                name
                url
              }
            }
          }
      }
    `);

    const projects = data.githubViewer.pinnedItems.nodes;

    return (
        <section className="slider">
            <div className="slider__controls">
                <ButtonIcon ariaLabel="Previous project" handleUserClick={prevSlide} className="slider__controls--button">
                    <ArrowLeftIcon />
                    <span>Prev</span>
                </ButtonIcon>
                <ButtonIcon ariaLabel="Next project" handleUserClick={nextSlide} className="slider__controls--button">
                    <span>Next</span>
                    <ArrowRightIcon />
                </ButtonIcon>
            </div>
            <Slider {...sliderSettings} ref={slider}>
                {projects.map(({ id, name, url, description, homepageUrl }) => <Slide key={id} title={name} description={description} demo={homepageUrl} repo={url} />)}
            </Slider>
        </section>
    );
}

export default ProjectsSlider;