import React, { useRef, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import './Slide.scss';
import GithubIcon from '../../../assets/icons/github.svg';
import WebIcon from '../../../assets/icons/web.svg';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg';
import ArrowRightIcon from '../../../assets/icons/arrow-right.svg';

const Slide = ({ title, description, repo, demo }) => {

    const [images, setImages] = useState([]);

    const slider = useRef(null);

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        swipe: false,
        touchMove: false,
        arrows: false,
        infinite: true,
        speed: 300,
    }

    const prevImage = () => slider.current.slickPrev();
    const nextImage = () => slider.current.slickNext();

    const fluidImage = useStaticQuery(graphql`
        query {
            file(name: {regex: "/under_construction/"}) {
            childImageSharp {
                fluid(maxWidth: 780) {
                    ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `);

    const underConstruction = fluidImage.file.childImageSharp.fluid;

    useEffect(() => {
        const getImages = async repo => {
            const req = await fetch(
                `https://api.github.com/repos/pkawula/${repo}/contents/src/images`
            );
            const data = await req.json();

            let arrayOfImages = [];

            if (data.length) {
                await data.forEach(({ name, download_url }) => {
                    if (
                        name.split(".").pop() === "png" ||
                        name.split(".").pop() === "jpg"
                    ) {
                        arrayOfImages.push(download_url);
                    }
                });
            }

            await setImages(arrayOfImages);
        };

        getImages(title);
    }, [title]);

    return (
        <article className="slideWrapper">
            <section className="slideWrapper__images">
                {images.length > 0 ?
                    <>
                        <div className="slideWrapper__images--controls">
                            <ButtonIcon handleUserClick={prevImage} ariaLabel="View prev image" className="slideWrapper__images--controlsIcon"><ArrowLeftIcon /></ButtonIcon>
                            <ButtonIcon handleUserClick={nextImage} ariaLabel="View next image" className="slideWrapper__images--controlsIcon"><ArrowRightIcon /></ButtonIcon>
                        </div>
                        <Slider {...sliderSettings} ref={slider}>
                            {images.map(image => <img key={image} className="slideWrapper__images--image" src={image} alt="application" />)}
                        </Slider>
                    </>
                    :
                    <div>
                        <Img fluid={underConstruction} className="slideWrapper__images--image" alt="Under construction" />
                    </div>
                }
            </section>
            <section className="slideWrapper__content">
                <h2 className="slideWrapper__content--heading">{title}</h2>
                <p className="slideWrapper__content--description">{description}</p>
                <div className="slideWrapper__content--buttons">
                    <ButtonIcon ariaLabel="Open source code" href={repo} className="slideWrapper__content--buttonsLink">
                        <GithubIcon />
                        <span>code</span>
                    </ButtonIcon>
                    <ButtonIcon ariaLabel="View demo" disabled={demo ? false : true} href={demo} className="slideWrapper__content--buttonsLink">
                        <WebIcon />
                        <span>demo</span>
                    </ButtonIcon>
                </div>
            </section>
        </article>
    );
};

Slide.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    repo: PropTypes.string.isRequired,
    setSwipe: PropTypes.func.isRequired,
    demo: PropTypes.string,
};

Slide.defaultProps = {
    demo: '',
}

export default Slide;